<template>
	<main class="container">
		<div v-if="loading" class="flex justify-center">
			<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
		</div>

		<div v-if="!loading" class="flex space-between mb-40">
			<div>
				<h1 class="mb-10">Leistir</h1>

				<p class="color-grey">Yvirlit yvir leistir til avtalusløg</p>
			</div>

			<router-link class="btn-add" :to="{ name: 'StepListCreate' }"></router-link>
		</div>

		<table>
			<thead>
				<th>Navn</th>
				<th>Stig</th>
				<th>&nbsp;</th>
			</thead>

			<tbody>
				<tr v-for="(stepList, index) in stepLists" :key="`step-list-${index}`" class="cursor-pointer" @click.prevent="editStepList(stepList.id)">
					<td>{{ stepList.name }}</td>

					<td>{{ stepList.steps ? stepList.steps.length : '-' }}</td>

					<td class="right" @click.stop="editStepList(stepList.id)">
						<i class="far fa-edit cursor-pointer"></i>
					</td>
				</tr>
			</tbody>
		</table>
	</main>
</template>

<script>
import axios from 'axios';

export default {
	name: 'StepLists',

	data() {
		return {
			loading: true,

			stepLists: [],
		};
	},

	async mounted() {
		axios
			.get('/steps/lists')
			.then((response) => {
				this.stepLists = response.data;
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	methods: {
		editStepList(stepListId) {
			this.$router.push({ name: 'StepListEdit', params: { id: stepListId } });
		},
	},
};
</script>
